import { createContext, useContext, useEffect, useState } from "react";
import { setAuthorization } from "../api-client";
import { UserDto } from "../submodules/Assessment-Platform-1.0-Dtos-FE/src/dtos/user.dto";
import { UserRole } from "../submodules/Assessment-Platform-1.0-Dtos-FE/src/enums/userRole.enum";

import tenantService from "../api-client/services/tenant";

import { message } from "antd";
import {
  TenantSettingsResponse,
  defaultTenantSettings,
} from "../submodules/Assessment-Platform-1.0-Dtos-FE/src/dtos/tenant/index.dto";
import {
  dropCookies,
  generateCookiesKey,
  isTimeDifferenceLessThan6Hours,
  dropAuthCookies,
} from "../submodules/Assessment-Platform-1.0-Dtos-FE/src/utils/context";
import { getSubdomain } from "../submodules/Assessment-Platform-1.0-Dtos-FE/src/utils/domain";
import {
  LocalStorageExistence,
  CookiesExistence,
  DomainCookiesClear,
} from "../submodules/Assessment-Platform-1.0-Dtos-FE/src/utils/storage";
import { decryptText, encryptText } from "../utils/encrypt-decrypt";
import authService from "../api-client/services/auth";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { metaDataParamsKey } from "../containers/middleware";

const logoutChannel = new BroadcastChannel("logout");

export const keepLocalStorage = () => {};

export const genRole = (role: UserRole) => {
  if (role === UserRole.LEAD) {
    localStorage.setItem("isLead", "true");
    return UserRole.ADMIN;
  } else return role;
};
interface ApplicationContextType {
  token: string;
  user: UserDto;
  auth: AuthType;
  tenantSettings: TenantSettingsResponse;
  shouldTenantRefresh: RefreshType;

  setToken: (token: string) => void;
  setAuth: (auth: AuthType) => void;
  setUser: (user: UserDto) => void;
  logout: (isUnauthorized?: boolean) => void;
  login: (userData: UserLoginResponse) => void;
  setTenantSettings: (tenantSettings: TenantSettingsResponse) => void;
  setShouldTenantRefresh: (isRefresh: RefreshType) => void;
}

export const setUserToLocalStorage = (user: any) => {
  console.log("local storage...", user);
  if (user && user?.email) {
    const stringUser = JSON.stringify(user);
    localStorage.setItem("user", stringUser);
  }
};

type UserLoginResponse = {
  access_token: string;
  role: UserRole;
  user_id: number;
  username: string;
};

type RefreshType = {
  auto: boolean;
  manual: boolean;
};

const initRefreshType: RefreshType = {
  auto: false,
  manual: false,
};

type AuthType = {
  userExists: boolean;
  userLoading: boolean;
};

const initUser: AuthType = {
  userExists: false,
  userLoading: true,
};

const defaultUserData: UserDto = {
  id: undefined,
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  profileImage: "",
  role: undefined,
  referralLink: "",
  status: undefined,
};

const applicationDefaultValue: ApplicationContextType = {
  token: "",
  user: defaultUserData,
  auth: initUser,
  tenantSettings: defaultTenantSettings,
  shouldTenantRefresh: initRefreshType,

  setToken: () => {
    // return void;
  },
  setAuth: () => {
    // return void;
  },
  setUser: () => {
    // return void;
  },
  logout: (isUnauthorized?: boolean) => {
    // return void;
  },
  login: (userData: UserLoginResponse) => {
    //return void;
  },
  setTenantSettings: (tenantSettings: TenantSettingsResponse) => {
    //return void
  },
  setShouldTenantRefresh: (isRefresh: RefreshType) => {
    //return void
  },
};

export const ApplicationContext = createContext<ApplicationContextType>(
  applicationDefaultValue
);

type ApplicationContextProviderProps = {
  children: React.ReactNode;
};

export const ApplicationContextProvider: React.FC<
  ApplicationContextProviderProps
> = ({ children }) => {
  // const location = useLocation();

  const [token, setToken] = useState<string>("");
  const [user, setUser] = useState<UserDto>(applicationDefaultValue.user);
  const [auth, setAuth] = useState<AuthType>(initUser);
  const [tenantSettings, setTenantSettings] = useState<TenantSettingsResponse>(
    defaultTenantSettings
  );

  console.log({ user });
  const [shouldTenantRefresh, setShouldTenantRefresh] =
    useState<RefreshType>(initRefreshType);

  useEffect(() => {
    // Parse query parameters using query-string
    const { md } = queryString.parse(window.location.search);

    if (md && !sessionStorage.getItem(metaDataParamsKey)) {
      try {
        const parsedMetadata = JSON.parse(md as any);
        sessionStorage.setItem(metaDataParamsKey, md as any);
        initializeApp(parsedMetadata.tenantId);
      } catch (error) {
        console.error("Failed to parse metadata:", error);
      }
    }

    const newUrl = window.location.pathname;
    window.history.replaceState({}, document.title, newUrl);

    async function initializeApp(tenantId: number) {
      const storedToken = localStorage.getItem("token");
      const storedUserId = localStorage.getItem("userid");

      await fetchTenantSettings(tenantId);

      if (storedToken && storedUserId) {
        setAuthorization(storedToken);
        await getUserLoggedInStatus(storedToken, storedUserId);
      }

      logoutAllTabs();
    }
  }, []);

  const fetchTenantSettings = async (tenantId: number) => {
    const existedTenant = LocalStorageExistence("tenant-config");
    if (!existedTenant.isExist) {
      try {
        const response = await tenantService.getTenantSettings(tenantId);
        if (response.status === 200) {
          const tenant = await adminTenantSetupOnResponse(response.data!);
          onSuccessTenantSetup(tenant!);
        }
      } catch (error) {
        onErrorTenantSetup();
      }
    } else {
      try {
        const tenant: TenantSettingsResponse = existedTenant.data;
        onSuccessTenantSetup(tenant!, false);

        const response = await tenantService.getTenantSettings(tenantId);

        if (response.status === 200) {
          const updatedTenant = await adminTenantSetupOnResponse(
            response.data!
          );

          const isMaintenance =
            updatedTenant?.tenantConfig?.isMaintenance !==
            tenant.tenantConfig.isMaintenance;

          const isUpdated =
            updatedTenant?.tenantConfig.updatedAt !==
            tenant.tenantConfig.updatedAt;

          if (isMaintenance) {
            setShouldTenantRefresh({
              auto: true,
              manual: false,
            });
            onSuccessTenantSetup(updatedTenant!);
            return;
          }

          if (isUpdated) {
            setShouldTenantRefresh({
              auto: false,
              manual: true,
            });
            onSuccessTenantSetup(updatedTenant!);
          }
        }
      } catch (error) {
        onErrorTenantSetup();
      }
    }
  };

  const onSuccessTenantSetup = (
    tenant: TenantSettingsResponse,
    isUpdateStorage: boolean = true
  ) => {
    dropCookies(tenant);
    setTenantSettings(tenant);
    if (isUpdateStorage)
      localStorage.setItem("tenant-config", JSON.stringify(tenant));
  };

  const onErrorTenantSetup = () => {
    dropCookies(defaultTenantSettings);
    setTenantSettings(defaultTenantSettings);
  };

  const adminTenantSetupOnResponse = async (tenant: TenantSettingsResponse) => {
    let cTenantConfig = tenant?.tenantConfig;
    const updatedTenantConfig = cTenantConfig?.admin
      ? cTenantConfig.admin
      : cTenantConfig;

    let updatedTenant = {
      ...tenant!,
      tenantConfig: updatedTenantConfig!,
    };
    return updatedTenant;
  };

  const getUserLoggedInStatus = async (token: string, userId: string) => {
    const existedTenant = LocalStorageExistence("tenant-config");
    const tenantId = existedTenant.data.id;
    const secureUserKey = generateCookiesKey(+userId, +tenantId);
    const authCookies = CookiesExistence(`AA${secureUserKey}`, true);
    const lutCookies = CookiesExistence(`ALT${secureUserKey}`, true);

    const isAuthUser = isTimeDifferenceLessThan6Hours(
      Date.now(),
      +lutCookies.data
    );

    if (isAuthUser) {
      if (authCookies.isExist) {
        let decryptUserData = decryptText(
          authCookies.data,
          process.env.REACT_APP_SECRET_AUTH_VALIDATE!
        );
        if (token) setToken(token);
        setAuthorization(token);
        const userData = JSON.parse(decryptUserData);

        if (!auth.userExists) {
          setAuth({
            userExists: true,
            userLoading: false,
          });
        }
        if (userData) {
          setUser(userData);
          setUserToLocalStorage(userData);
        }

        return;
      }
    }

    const response = await authService.getAuthenticatedUser(token, userId);

    if (response.data) {
      if (token) setToken(token);
      setAuthorization(token);
      let userData = response.data;
      if (!auth.userExists) {
        setAuth({
          userExists: true,
          userLoading: false,
        });
      }
      if (userData) {
        userData.role = genRole(userData.role!);

        setUser(userData);
        setUserToLocalStorage(userData);

        const encryptUserData = await encryptText(
          JSON.stringify(userData),
          process.env.REACT_APP_SECRET_AUTH_VALIDATE!
        );

        dropAuthCookies(`AA${secureUserKey}`, encryptUserData);
      }
    } else {
      setAuth({
        userExists: false,
        userLoading: false,
      });
      setToken("");
      setUser(applicationDefaultValue.user);
      setUserToLocalStorage(applicationDefaultValue.user);

      const isLead = localStorage.getItem("isLead");
      const isFirst = localStorage.getItem("isFirst");

      localStorage.clear();

      localStorage.setItem("isLead", isLead!);
      localStorage.setItem("isFirst", isFirst!);
      sessionStorage.clear();
      DomainCookiesClear(`AA${secureUserKey}`);
      DomainCookiesClear(`ALT${secureUserKey}`);
    }
  };

  const logoutAllTabs = () => {
    logoutChannel.onmessage = () => {
      logout();
      logoutChannel.close();
    };
  };

  function logout(isUnauthorized?: boolean) {
    if (isUnauthorized) {
      message.error("Your session is expired");
    }

    const secureUserKey = generateCookiesKey(user.id!, tenantSettings.id);
    logoutChannel.postMessage("Logout");
    setAuth({
      userExists: false,
      userLoading: false,
    });
    setToken("");
    setUser(applicationDefaultValue.user);
    setUserToLocalStorage(applicationDefaultValue.user);
    const itemToKeep = localStorage.getItem("updatedAt");
    const isLead = localStorage.getItem("isLead");
    const isFirst = localStorage.getItem("isFirst");

    localStorage.clear();
    localStorage.setItem("updatedAt", itemToKeep!);

    localStorage.setItem("isLead", isLead!);
    localStorage.setItem("isFirst", isFirst!);
    sessionStorage.clear();
    DomainCookiesClear(`AA${secureUserKey}`);
    DomainCookiesClear(`ALT${secureUserKey}`);
  }

  function login(userData: UserLoginResponse) {
    setAuth((prevState) => {
      return {
        ...prevState,
        userExists: true,
        userLoading: false,
      };
    });
    setUser({
      ...user,
      role: genRole(userData.role!),
      id: userData.user_id,
    });
    setUserToLocalStorage({
      ...user,
      role: genRole(userData.role!),
      id: userData.user_id,
    });
    setToken(userData.access_token);
  }

  const value: ApplicationContextType = {
    token,
    auth,
    user,
    tenantSettings,
    shouldTenantRefresh,

    setToken,
    setAuth,
    setUser,
    logout,
    login,
    setTenantSettings,
    setShouldTenantRefresh,
  };

  return (
    <ApplicationContext.Provider value={value}>
      {children}
    </ApplicationContext.Provider>
  );
};

export const useApplicationContext = () => {
  return useContext(ApplicationContext);
};
