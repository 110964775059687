let base_url: string = "";
let user_srvc_url: string = "";
let mul_srvc: string = "";

let local_srvc = "";
if (process.env.REACT_APP_ENV == "development") {
  user_srvc_url = "https://user-dev.refactor.academy";
  base_url = "https://gateway-dev.refactor.academy";
  mul_srvc = "https://proctorgateway-dev.refactor.academy";
} else if (process.env.REACT_APP_ENV == "production") {
  base_url = "https://gateway-prod.refactor.academy";
  user_srvc_url = "https://user-prod.refactor.academy";
  mul_srvc = "https://proctorgateway-prod.refactor.academy";
} else if (process.env.REACT_APP_ENV == "staging") {
  base_url = "https://gateway-staging.refactor.academy";

  local_srvc = "http://localhost:6005";

  user_srvc_url = "https://user-staging.refactor.academy";
  mul_srvc = "https://proctorgateway-staging.refactor.academy";
}

export const MANAGER_ENDPOINTS = {
  GET_MANUAL_TEMPLATE: `${base_url}/hmsrvc/manual-feedback/get-template`,
};

export const INTG_ENDPOINTS = {
  GET_ALL_INTG: `${base_url}/apt-srvc/ms-teams/get-all-intg`,
  GET_TOKEN_BY_CODE: `${base_url}/apt-srvc/ms-teams/ms/get-token-by-code`,
  GET_ALL_CAL: `${base_url}/apt-srvc/ms-teams/ms/get-calenders`,
  SAVE_CALENDAR_ID: `${base_url}/apt-srvc/ms-teams/ms/add-calendar-id`,
  TOGGLE_INTG: `${base_url}/apt-srvc/ms-teams/ms/update-intg`,
  CREATE_EVENT: `${base_url}/apt-srvc/ms-teams/ms/create-event`,
  GET_ACCESS_TOKEN: `${base_url}/apt-srvc/ms-teams/ms/get-token-by-refresh-user-id`,
  GET_EVENTS_MS: `${base_url}/apt-srvc/ms-teams/ms/get-events`,
  UPDATE_EVENT: `${base_url}/apt-srvc/ms-teams/ms/update-event`,
  DELETE_EVENT: `${base_url}/apt-srvc/ms-teams/ms/delete-event`,
  GET_EVENTS_OTHERS: `${base_url}/apt-srvc/ms-teams/ms/get-events-others`,
};

export const MEETING_ENDPOINTS = {
  GET_ACS_TOKEN: `${base_url}/apt-srvc/ms-teams/get-user-acs-token`,
};
