import {
  AzureCommunicationTokenCredential,
  MicrosoftTeamsUserIdentifier,
} from "@azure/communication-common";
import {
  CallComposite,
  CallCompositeOptions,
  CompositeLocale,
  TeamsCallAdapter,
  useTeamsCallAdapter,
  Profile,
} from "@azure/communication-react";
import { useCallback, useEffect, useMemo } from "react";
import { MeetingGUIContainer } from "./style";
import CompanyLogo from "../company-logo";
import { useApplicationContext } from "../../context/application-context";
import { isMobileWidth } from "../../app-routes";

export type ContainerProps = {
  userId: MicrosoftTeamsUserIdentifier;
  token: string;
  formFactor?: "desktop" | "mobile";
  callInvitationURL?: string;
  locale?: CompositeLocale;
  options?: CallCompositeOptions;
  meetingUrl?: string;
  locator?: string;
};

export const TeamsMeeting = (props: ContainerProps): JSX.Element => {
  const { tenantSettings } = useApplicationContext();

  useEffect(() => {
    // init();
  }, []);

  // const init = async () => {
  //   try {
  //     const callClient = new CallClient();
  //     const tokenCredential = new AzureCommunicationTokenCredential(
  //       props.token
  //     );
  //     const callAgent = await callClient.createTeamsCallAgent(tokenCredential);

  //     console.log("lobby......", callAgent);

  //     const locator = { meetingLink: props.meetingUrl! };
  //     const call = callAgent.join(locator);

  //     console.log("lobby......", call.lobby.participants);

  //     try {
  //       console.log("just before");
  //       call.lobby.admitAll();
  //     } catch (err) {
  //       console.log("admit all error", err);
  //     }
  //   } catch (errr) {
  //     console.log({ errr });
  //   }
  //   // console.log({ callAgent });
  //   // const deviceManager = await callClient.getDeviceManager();
  // };

  const credential = useMemo(() => {
    try {
      return new AzureCommunicationTokenCredential(props.token); // <-- This props.token would be your Teams access token
    } catch (error) {
      console.error("Failed to construct token credential", error);
      return undefined;
    }
  }, [props.token]);

  const onFetchProfile = useCallback(
    async (userId: string, defaultProfile?: Profile): Promise<Profile> => {
      if (defaultProfile?.displayName) {
        return defaultProfile;
      }
      return { displayName: "arjun acc test user" };
    },
    []
  );

  const options = useMemo(
    () => ({
      onFetchProfile,
    }),
    [onFetchProfile]
  );

  const teamsCallAdapterArgs = useMemo(
    () => ({
      userId: props.userId,
      credential,
      locator: props.meetingUrl
        ? {
            meetingLink: props.meetingUrl,
          }
        : undefined,
      options,
    }),
    [props.userId, credential, props.meetingUrl, options]
  );

  const adapter = useTeamsCallAdapter(
    teamsCallAdapterArgs,
    undefined,
    leaveCall
  );

  if (!props.meetingUrl) {
    return <>Teams meeting link is not provided.</>;
  }

  if (adapter) {
    return (
      <MeetingGUIContainer>
        <CallComposite
          options={{
            branding: {
              logo: {
                url: tenantSettings?.tenantConfig.brandLogo.darkBgLogo,
                alt: "",
                shape: "unset",
              },
              backgroundImage: {
                url: tenantSettings?.tenantConfig.loginBgURL,
              },
            },
          }}
          adapter={adapter}
          formFactor={isMobileWidth ? "mobile" : "desktop"}
          callInvitationUrl={props?.callInvitationURL}
          locale={props?.locale}
        />
      </MeetingGUIContainer>
    );
  }
  if (credential === undefined) {
    return <>Failed to construct credential. Provided token is malformed.</>;
  }
  return (
    <div
      style={{
        height: "100vh",
        width: "60vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <style>
        {`
      @keyframes growShrink {
        0% { transform: scale(1); }
        50% { transform: scale(1.5); }
        100% { transform: scale(1); }
      }

      .animatedLogo {
        animation: growShrink 2s infinite;
      }
    `}
      </style>
      <div className="animatedLogo">
        <CompanyLogo
          brandLogo={tenantSettings.tenantConfig.brandLogo.whiteBgLogo}
        />
      </div>
    </div>
  );
};

const leaveCall = async (adapter: TeamsCallAdapter): Promise<void> => {
  await adapter.leaveCall().catch((e) => {
    console.error("Failed to leave call", e);
  });
};
