import { HiringManagerPostPayloadDto } from "../hm-dtos";

export enum IntgOrgEnum {
  MS = "MS",
  GOOGLE="google"
  // Add other integration organizations if needed
}

export interface MSIntegrationDetails {
  isIntg: boolean;
  loginUrl: string | null;
}

export interface MsIntgReturnPayload {
  userId: string; // Assuming userId is of type string, please adjust accordingly
  [IntgOrgEnum.MS]: MSIntegrationDetails;
  [IntgOrgEnum.GOOGLE]: MSIntegrationDetails;
}

export interface getTokenByCodePayloadDto {
  email: string;
  name: string;
  userId: string;
  tenantId: string;
  isIntegrated: boolean;
  code: string;
  tenantUrl: string;
  calendarId?: string;
}

export interface CalendarListItem {
  id: string;
  name: string;
  color: string;
  hexColor: string;
  isDefaultCalendar: boolean;
  changeKey: string;
  canShare: boolean;
  canViewPrivateItems: boolean;
  canEdit: boolean;
  allowedOnlineMeetingProviders: string[];
  defaultOnlineMeetingProvider: string;
  isTallyingResponses: boolean;
  isRemovable: boolean;
  owner: {
    name: string;
    address: string;
  };
}

interface StartEndDateTime {
  dateTime: string;
  timeZone: string;
}

interface EmailAddress {
  id?: number;
  address: string;
  name: string;
}

export interface Attendee {
  emailAddress: EmailAddress;
  type: string;
}

interface Payload {
  subject: string;
  body: {
    contentType: string;
    content: string;
  };
  start: StartEndDateTime;
  end: StartEndDateTime;
  attendees: Attendee[];
  isOnlineMeeting: boolean;
  onlineMeetingProvider: string;
}

export interface CreateEventMsPayloadDto {
  accessToken: string;
  payload: Payload;
  userId: string;
  calendarId: string;
  reminderMinutesBeforeStart: number;
  eventId: string;
  candidateDetails: HiringManagerPostPayloadDto;
}

export interface CandidateDetailsDto {
  candidateId: number;
  jobRole: string;
  stageId: number;
  workflowId: number;
  interviewId: number;
  attemptNum: number;
  courseId: number;
  hmIds: string[];
}

export interface MsEventDto {
  "@odata.context": string;
  "@odata.etag": string;
  id: string;
  subject: string;
  bodyPreview: string;
  webLink: string;
  body: {
    contentType: string;
    content: string;
  };
  start: {
    dateTime: string;
    timeZone: string;
  };
  end: {
    dateTime: string;
    timeZone: string;
  };
  location: Location;
  attendees: Attendee[];
  organizer: {
    emailAddress: EmailAddress;
  };
  onlineMeeting: {
    joinUrl: string;
  };
}
