import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { FC } from "react";
import { IMSUserProfile } from "../../utils/login-helper";
import { loginRequest } from "../../utils/msal-config";
import { SignInButtonContainer } from "./style";
import { MicrosoftIcon } from "../../assests/svg-images/MicrosoftIcon";

interface IMicrosoftLoginProps {
  handleMicrosoftResponse: (payload: IMSUserProfile) => void;
  buttonText?: string;
}

export const MicrosoftLogin: FC<IMicrosoftLoginProps> = (_props) => {
  const { handleMicrosoftResponse, buttonText = "Microsoft" } = _props;

  const { instance, inProgress } = useMsal();

  const handleLogin = async () => {
    // if (inProgress) {
    //   console.warn("Interaction in progress, please wait.");
    //   return;
    // }

    try {
      const response = await instance.loginPopup(loginRequest);

      if (response && response.account) {
        const accessToken = response.accessToken;
        const userEndpoint = "https://graph.microsoft.com/v1.0/me";

        const userResponse = await fetch(userEndpoint, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const userData = await userResponse.json();
        handleMicrosoftResponse(userData);
      }
    } catch (error) {
      console.error("Login failed: ", error);
    }
  };

  return (
    <SignInButtonContainer onClick={() => handleLogin()}>
      <div className="next-wrapper">
        <div className={"next-inner-wrapper"} style={{ padding: "15px 30px" }}>
          <div style={{ paddingTop: "6px" }}>
            <MicrosoftIcon />
          </div>
          <div>{buttonText}</div>
        </div>
      </div>
    </SignInButtonContainer>
  );
};
