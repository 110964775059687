export const LOGIN_ROUTES = {
  HOME: "/",
  RESET_PASSWORD: "/reset-password",
  USER_STATUS_UPDATE: `/link/status`,
  TENANT_LOGIN: "/login/:data",
};

export const MEETING_ROUTES = {
  JOIN_MEETING_HM: "/:tenantName/hm/join",
  JOIN_MEETING_CANDIDATE: "/candidate/join",
  JOIN_MEETING_NORMAL: "/join",
};

export const MS_CALENDER_ROUTES = {
  LOGIN: "/ms-login",
};
