import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MS_ALL_CLIENT_ID!,

    // clientId: "07216fe3-01eb-45db-9f8f-a22ed5ae7225",
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
