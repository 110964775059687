import { Modal, Result, Switch } from "antd";
import React, { useMemo, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { useApplicationContext } from "./context/application-context";
import Cookies from "js-cookie";
import {
  LOGIN_ROUTES,
  MEETING_ROUTES,
  MS_CALENDER_ROUTES,
} from "./constants/routes";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TenantHelmet from "./components/tenant-helmet";
import InitialLoader from "./components/initial-loader";
import {
  IDefaultTheme,
  createTheme,
} from "./submodules/Assessment-Platform-1.0-Dtos-FE/src/dtos/tenant/index.dto";
import { TeamsMeeting } from "./components/meeting-gui-hm";
import Middleware from "./containers/middleware";
import HmMeeting from "./containers/meeting/hm-meeting";
import RefreshMSToken from "./components/refresh-ms-token";
import GetCodeMs from "./containers/integration/ms/get-code";
import CandidateMeeting from "./containers/meeting/candidate-meeting";

const TenantHelmetMemoized = React.memo(TenantHelmet);

export const isMobileWidth = window.innerWidth < 1000;

const AppRoute = React.memo(() => {
  const { auth, tenantSettings, user } = useApplicationContext();
  const theme = useMemo(() => createTheme(tenantSettings), [tenantSettings]);
  const isMasterLogin = Cookies.get("isMasterLogin");
  const [modal, contextHolder] = Modal.useModal();

  const handleReloadButtonClick = () => {
    window.location.reload();
  };

  const mainContent = useMemo(
    () => (
      <Routes>
        <Route
          path={LOGIN_ROUTES.HOME}
          element={
            <>
              {/* <Middleware> */}
              <div>
                {/* <TeamsMeeting
                  userId={{
                    microsoftTeamsUserId:
                      "971971b6-fc8c-47c1-a411-ed62ec162946",
                    isAnonymous: false,
                  }}
                  meetingUrl="https://teams.microsoft.com/l/meetup-join/19%3ameeting_Yjg0OTUxNTAtNjE1OS00NWM2LTlkOGMtOWU3MDc4MjYzOWIy%40thread.v2/0?context=%7b%22Tid%22%3a%22c1117dd5-465f-4ec6-86c2-c708d2e0ab15%22%2c%22Oid%22%3a%22971971b6-fc8c-47c1-a411-ed62ec162946%22%7d"
                  token="eyJhbGciOiJSUzI1NiIsImtpZCI6IjYwNUVCMzFEMzBBMjBEQkRBNTMxODU2MkM4QTM2RDFCMzIyMkE2MTkiLCJ4NXQiOiJZRjZ6SFRDaURiMmxNWVZpeUtOdEd6SWlwaGsiLCJ0eXAiOiJKV1QifQ.eyJza3lwZWlkIjoib3JnaWQ6OTcxOTcxYjYtZmM4Yy00N2MxLWE0MTEtZWQ2MmVjMTYyOTQ2Iiwic2NwIjoxMDI0LCJjc2kiOiIxNzE5MzA3ODk2IiwiZXhwIjoxNzE5MzEyMjQ1LCJyZ24iOiJpbiIsInRpZCI6ImMxMTE3ZGQ1LTQ2NWYtNGVjNi04NmMyLWM3MDhkMmUwYWIxNSIsImFjc1Njb3BlIjoidm9pcCxjaGF0IiwicmVzb3VyY2VJZCI6IjQxMGUzZGFmLTU3Y2YtNDFmMy05ZDdlLTU1ZDYzMGMwYjhhZiIsImFhZF9pYXQiOiIxNzE5MzA3ODk2IiwiYWFkX3V0aSI6InRwWGJha253RDBHM2dINmNSNWlhQUEiLCJhYWRfYXBwaWQiOiIxZmQ1MTE4ZS0yNTc2LTQyNjMtODEzMC05NTAzMDY0YzgzN2EiLCJpYXQiOjE3MTkzMDgxOTZ9.koNBVYb0DYjxsV3H7zjudznNr2Vo2YFx0Cs7WpZ7ztQRliDhTZYy_glLa7HBOLy34NCv8-GaAjejfl9Tt2s7ZhYdBLFOAtGmnYHzdmaU5K74s9B4IEsVIt7FYZXDt9DcAaHx7bEym9ruANFZ3FZ_2VKeZe3lsddW7Ukoc5ATLQgxDoGW6S9Wckji1SvsXDfW5Nt4A3Bjk5egbU_pdjovxvhmd_6ltgSJMxnFK-x_MW68rpK9LQpXnORMUXG0rzfg7EqE33GAUGgy7k8PSbFDMGz4HOsGdizQ9Ydfpel9gdLcuybmTu8GFAatUplqwk99uJv2bq_ZTmlaAtcPClDRdg"
                /> */}
                <Result
                  status="403"
                  title="401"
                  subTitle="Sorry, you are not authorized to access this page."
                />
              </div>
              {/* </Middleware> */}
            </>
          }
        />

        <Route path={MS_CALENDER_ROUTES.LOGIN} element={<GetCodeMs />} />

        <Route
          path={MEETING_ROUTES.JOIN_MEETING_HM}
          element={
            <Middleware>
              <RefreshMSToken>
                <HmMeeting />
              </RefreshMSToken>
            </Middleware>
          }
        />

        <Route
          path={MEETING_ROUTES.JOIN_MEETING_CANDIDATE}
          element={<CandidateMeeting />}
        />
      </Routes>
    ),
    [auth.userExists]
  );

  return (
    <>
      <TenantHelmetMemoized helmetProps={tenantSettings.tenantConfig.helmet!} />
      <div className="main-content">
        <React.Suspense
          fallback={
            <div
              style={{ width: "100vw", height: "100vh", overflow: "hidden" }}
            >
              <InitialLoader />
            </div>
          }
        >
          <Router>
            <ThemeProvider theme={theme as IDefaultTheme}>
              {mainContent}
            </ThemeProvider>
          </Router>
        </React.Suspense>
      </div>
      {contextHolder}
    </>
  );
});

export default AppRoute;
